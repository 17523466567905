<template>
  <div>
    <!-- banner -->
    <div class="bannerbox">
      <!-- <img src="https://qn.icgushi.com/NANJIXIONGWENHUA%3ABANNER.png" alt="" /> -->
       <img src="https://qn.icgushi.com/NANJIXIONG/WENHUA.png" alt="" />
    </div>
    <!-- tab -->
    <div class="tabBox">
      <div class="tabContect">
        <div class="aa" @click="changeitem(1)">
          企业愿景
          <div class="point" v-show="activeitem == 1"></div>
        </div>
        <div class="aa" @click="changeitem(2)">
          员工愿景
          <div class="point" v-show="activeitem == 2"></div>
        </div>
      </div>
    </div>
    <!-- tab 对于的内容 -->
    <div class="tabzero" v-show="activeitem == 0">
      <div class="title">企业文化</div>
      <div class="company">南极熊科技有限公司</div>
      <div class="context">
        南极熊科技公司拥有专业的营销团队和高效的营销网络，公司的核心团队都是在业界从事经验达十五年以上的专业人员。我们以消费电子、手机通讯、平板、车载、<br />
        工控、医疗等电子行业市场为服务对象，专注于为国内外知名生产厂家提供全面的优质电子元器件产品、产品应用支持、方案设计、售后技术及物流等方面的专业服务。业务<br />
        涉及分销、短缺、成本降低、库存处理的全方位服务。我们的专业团队每天通过公司数据库管理着全球几十个国家，上千个贸易伙伴的百万条元器件信息，并与北美，欧洲，<br />
        亚洲地区的各大工厂、原厂和代理商有着紧密的业务往来。
      </div>
      <div class="context">
        公司在质量管理方面有严格的标准与要求，建立了更标准的质量管理体系和服务体系，也获得IS9001、ISO14001等国际质量体系认证；同时积极推进产业整合，拓展战略布局，<br />
        通过加强产业上下游合作、优化供应链管理，共同推进半导体领域的技术创新。
      </div>
      <!-- 图文 -->
      <div class="picAt">
        <div class="pAtBox" v-for="item in cultureList" :key="item.tt">
          <img :src="item.img" alt="" class="imga" />
          <div class="tta">{{ item.tt }}</div>
        </div>
      </div>
      <!-- 企业意愿 -->
      <div class="companyIntendTo">
        <div class="title">企业愿景</div>
        <img
          src="https://qn.icgushi.com/NANJIXIONGWEN%3AQIYAUN.png"
          alt=""
          class="img"
        />
      </div>
      <!-- 员工意愿 -->
      <div class="workerIntendTo">
        <div class="title">员工愿景</div>
        <img
          src="https://qn.icgushi.com/NANJIXIONGWEN%3AYUANYUAN.png"
          alt=""
          class="img"
        />
      </div>
    </div>
    <!-- 单独点击 企业意愿时 -->
    <div v-show="activeitem == 1" class="companyIntendTo">
      <div class="title">企业愿景</div>
      <img
        src="https://qn.icgushi.com/NANJIXIONGWEN%3AQIYAUN.png"
        alt=""
        class="img"
      />
    </div>
    <!-- 单独点击 员工意愿时 -->
    <div v-show="activeitem == 2" class="workerIntendTo">
      <div class="title">员工愿景</div>
      <img
        src="https://qn.icgushi.com/NANJIXIONGWEN%3AYUANYUAN.png"
        alt=""
        class="img"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeitem: 0,
      cultureList: [
        {
          img: "https://qn.icgushi.com/NANJIXIONGWEN%3AWENA.png",
          tt: "公平",
        },
        {
          img: "https://qn.icgushi.com/NANJIXIONGWEN%3AWENB.png",
          tt: "严谨",
        },
        {
          img: "https://qn.icgushi.com/NANJIXIONGWEN%3AWENC.png",
          tt: "高效",
        },
        {
          img: "https://qn.icgushi.com/NANJIXIONGWEN%3AWEND.png",
          tt: "合作",
        },
        {
          img: "https://qn.icgushi.com/NANJIXIONGWEN%3AWENE.png",
          tt: "创新",
        },
      ],
    };
  },
  methods: {
    changeitem(e) {
      this.activeitem = e;
    },
  },
};
</script>

<style scoped lang='scss'>
.bannerbox {
  width: 100%;
  height: 480px;
  img {
    width: 100%;
    height: 480px;
  }
}
// tab
.tabBox {
  width: 100%;
  height: 73px;
  background: #f2f2f2;
  .tabContect {
    width: 1320px;
    height: 73px;
    margin: 0 auto;
    // border: 1px solid red;
    display: flex;
    .aa {
      width: 169px;
      height: 73px;
      line-height: 73px;
      background: #fff;
      text-align: center;
      position: relative;
      border: 1px solid #979797;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1b4b62;
      .point {
        position: absolute;
        bottom: 9px;
        left: 80px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #3fadc0;
      }
    }
  }
}
// 主体内容
.tabzero {
  width: 1320px;
  margin: 0 auto;
  .title {
    width: 1320px;
    text-align: center;
    font-size: 34px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-top: 143px;
    margin-bottom: 35px;
  }
  .company {
    width: 1320px;
    text-align: center;
    margin-bottom: 24px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  .context {
    width: 1299px;
    // height: 211px;
    margin: 0 auto;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 30px;
    text-indent: 2em;
  }
  .picAt {
    width: 1320px;
    height: 300px;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .pAtBox {
      width: 200px;
      text-align: center;
      .imga {
        width: 200px;
        height: 200px;
      }
      .tta {
        margin-top: 18px;
        font-size: 36px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
// 企业意愿
.companyIntendTo {
  width: 1320px;
  margin: 0 auto;
  margin-bottom: 179px;
  .title {
    width: 1320px;
    text-align: center;
    font-size: 34px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-top: 143px;
    margin-bottom: 69px;
  }
  .img {
    width: 1299px;
    height: 421px;
    margin: 0 auto;
  }
}
// 员工意愿
.workerIntendTo {
  width: 1320px;
  margin: 0 auto;
  margin-bottom: 179px;
  .title {
    width: 1320px;
    text-align: center;
    font-size: 34px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-top: 143px;
    margin-bottom: 69px;
  }
  .img {
    width: 1299px;
    height: 521px;
  }
}
</style>
